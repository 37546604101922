import React from 'react';
import { graphql } from 'gatsby';
import logo from '../../content/images/back3.png';

import Layout from '../components/layout';
import Wrapper from '../components/Wrapper';
import Hero from '../components/Hero';
import PostsList from '../components/PostsList';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';

class BlogList extends React.Component {
  render() {
    const { title, description } = this.props.data.site.siteMetadata;
    const posts = this.props.data.posts.edges;
    const { pageContext } = this.props;

    return (
      <Layout location={this.props.location}>
        <SEO imageShare={logo} />
        <Hero title={title} subTitle={description} />

        <Wrapper>
          <PostsList posts={posts} />
        </Wrapper>

        <Pagination
          nbPages={pageContext.nbPages}
          currentPage={pageContext.currentPage}
        />
      </Layout>
    );
  }
}

export default BlogList;

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    posts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "//content/posts//" }
        frontmatter: { published: { ne: false }, unlisted: { ne: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            language
            slug
          }
        }
      }
    }
  }
`;
