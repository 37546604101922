import React from 'react';
import ukFlag from './en-flag.png';
import itaFlag from './ita-flag.png';

import '../styles/flag.scss';

class Flag extends React.Component {
  render() {
    const { language } = this.props;
    const img = language === 'en' ? ukFlag : itaFlag;
    const alt = language === 'en' ? 'English post' : 'Post in italiano';

    return <img src={img} alt={alt} className="flag" />;
  }
}

export default Flag;
